import type { Media } from '~/models/media'

export const useMedia = () => {
  const adminURL = useRuntimeConfig().public.gatewayAdminApi

  async function uploadMedia(file: File, category = 'image') {
    const formData = new FormData()

    formData.append('category', category)
    formData.append('media', file)

    const { data } = await useAuthFetch<{ data: Media }>('/media', {
      baseURL: adminURL,
      method: 'POST',
      body: formData,
    })

    return data.url
  }

  return {
    uploadMedia,
  }
}
