<template>
  <n-config-provider ref="menu" preflight-style-disabled class="absolute z-20">
    <div class="editor-menu p-3 shadow-xl">
      <slot>
        <div class="text-lg flex items-center justify-between mb-3 font-bold">
          {{ $t('admin.blocks') }}
        </div>
        <div class="flex flex-col gap-2">
          <div
            v-for="block in editorStore.blocksList"
            :key="block.type"
            class="list-group-item bg-gray-100 hover:bg-gray-200 duration-150 rounded-xl py-4 px-5 cursor-pointer"
            @click="onCreate(block)"
          >
            <p class="font-bold">
              {{ block.editorSchema.title }}
            </p>
            <p class="text-sm text-gray-500">
              {{ block.editorSchema.description }}
            </p>
          </div>
        </div>
        <template v-if="editorStore.templates.length">
          <div
            class="text-lg flex items-center justify-between mb-3 font-bold mt-8"
          >
            {{ $t('admin.templates') }}
          </div>
          <div class="flex flex-col gap-2">
            <div
              v-for="template in editorStore.templates"
              :key="template.id"
              class="list-group-item bg-gray-100 hover:bg-gray-200 duration-150 rounded-xl py-4 px-5 cursor-pointer flex items-center justify-between"
              @click="onCreateFromTemplate(template)"
            >
              <div>
                <p class="font-bold">
                  {{ template.name }}
                </p>
                <p v-if="template.description" class="text-sm text-gray-500">
                  {{ template.description }}
                </p>
              </div>
              <BaseButton
                size="xs"
                look="link"
                theme="destructive"
                :title="$t('common.delete')"
                @click.stop="onDeleteTemplate(template.id)"
              >
                <BaseIcon name="outline_trash" />
              </BaseButton>
            </div>
          </div>
        </template>
      </slot>
    </div>
    <TemplateDeleteModal
      v-if="deletingTemplateId"
      :id="deletingTemplateId"
      :is-open="isDeletingModalOpen"
      class="z-50"
      @close="isDeletingModalOpen = false"
    />
  </n-config-provider>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import TemplateDeleteModal from './template/TemplateDeleteModal.vue'
import type { EditorBlock, BlockTemplate } from '~/models/page-editor'
import { usePageEditor } from '~/stores/page-editor'

const editorStore = usePageEditor()

const emit = defineEmits(['close', 'create'])

const menu = ref(undefined)
const isDeletingModalOpen = ref(false)
const deletingTemplateId = ref<number | undefined>(undefined)

function onCreate(item: Partial<EditorBlock>) {
  const blockData = reactive({
    ...item,
    editorData: useCloneDeep(item.editorData),
    key: Symbol('key'),
    uuid: uuid(),
  })

  emit('create', blockData)
  emit('close')
}

function onCreateFromTemplate(template: BlockTemplate) {
  const blockData = {
    ...editorStore.blocksMap[template.type],
    editorData: useCloneDeep(JSON.parse(template.data || '{}')),
  }
  onCreate(blockData)
}

onClickOutside(menu, () => emit('close'), {
  ignore: ['.n-select', '.n-base-select-option'],
})

function onDeleteTemplate(templateId: number) {
  deletingTemplateId.value = templateId
  isDeletingModalOpen.value = true
}
</script>

<style scoped>
.editor-menu {
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 100px;
  z-index: 40;
  background: #fff;
  width: 360px;
  height: 100%;
  overflow: auto;
}
</style>
