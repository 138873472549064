<template>
  <BaseModal
    :title="t('admin.delete_template')"
    :is-open="isOpen"
    size="sm"
    @close="close"
  >
    <div class="mb-4">
      {{ t('admin.block_confirm_delete_text') }}
    </div>
    <div class="flex justify-between gap-4">
      <BaseButton look="link" class="!px-0" style="white" @click="close">
        {{ $t('common.cancel') }}
      </BaseButton>
      <BaseButton theme="primary" look="solid" type="submit" @click="submit">
        {{ $t('admin.confirm_delete_title') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useNotifications } from '~/stores/notifications'
import { usePageEditor } from '~/stores/page-editor'

const { pushNotification } = useNotifications()
const { deleteBlockTemplate } = usePageEditor()
const { t } = useI18n()

interface PropsInterface {
  isOpen: boolean
  id: number
}

const props = defineProps<PropsInterface>()

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}

const isDeleting = ref(false)

const submit = async () => {
  isDeleting.value = true
  try {
    await deleteBlockTemplate(props.id)
    pushNotification({
      title: t('notifications.editor.delete_template_success'),
    })
    close()
  } catch (error) {
    pushNotification({
      title: t('notifications.editor.delete_template_error'),
      theme: 'destructive',
    })
    useLogError(error)
  } finally {
    isDeleting.value = false
  }
}
</script>
