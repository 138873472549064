<template>
  <BaseModal
    :title="$t('admin.create_template')"
    :is-open="isOpen"
    fullscreen
    @close="close"
  >
    <BaseForm
      class="w-full flex flex-col gap-4"
      :method="submit"
      :reset-on-submit="false"
    >
      <BaseInput
        v-model="form.name"
        name="name"
        rules="required|max:48"
        :placeholder="$t('common.name')"
        :label="$t('common.name')"
        required
      />
      <BaseTextarea
        v-model="form.description"
        :label="$t('common.description')"
        :placeholder="$t('common.description')"
        class="w-full text-left"
        name="description"
        rows="2"
        rules="max:256"
      />

      <div class="flex justify-between gap-4 mt-4">
        <BaseButton theme="primary" look="link" class="!px-0" @click="close">
          {{ $t('common.cancel') }}
        </BaseButton>

        <BaseButton
          theme="primary"
          look="solid"
          type="submit"
          :loading="isLoading"
        >
          {{ $t('common.create') }}
        </BaseButton>
      </div>
    </BaseForm>
  </BaseModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useNotifications } from '~/stores/notifications'
import { usePageEditor } from '~/stores/page-editor'
import type { EditorBlock } from '~/models/page-editor'

const { pushNotification } = useNotifications()
const { createBlockTemplate } = usePageEditor()
const { t } = useI18n()

interface PropsInterface {
  isOpen: boolean
  block: EditorBlock
}

const props = defineProps<PropsInterface>()

const form = reactive({
  name: '',
  description: '',
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}

const isLoading = ref(false)

const submit = async () => {
  isLoading.value = true
  try {
    const newTemplate = {
      name: form.name,
      description: form.description,
      data: props.block.content,
      type: props.block.type,
    }
    await createBlockTemplate(newTemplate)
    pushNotification({
      title: t('notifications.editor.create_template_success'),
    })
    close()
  } catch (error) {
    pushNotification({
      title: t('notifications.editor.create_template_error'),
      theme: 'destructive',
    })
    useLogError(error)
  } finally {
    isLoading.value = false
  }
}
</script>
