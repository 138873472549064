export function iframeCode(link: string) {
  return `<iframe src="${link}" width="100%" height="100%" frameborder="0" scrolling="no"></iframe>`
}
export const supportCode = `<div id="iframe_popup"
  style="position: fixed; z-index: 100000; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; display: none;">
  <style>
    @keyframes spin {
      100% {
        transform: rotate(360deg)
      }
    }
  </style>
  <div id="iframe_loader"
    style="display: flex; height: 100vh; justify-content: center; align-items: center; background-color: rgb(140 153 171 / 0.75);">
    <svg style="animation: spin 1s linear infinite; height: 2rem; width: 2rem;" viewBox="0 0 24 24" fill="none">
      <circle style="opacity: 0.25;" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
      <path style="opacity: 0.75;" fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
  </div>
</div>

<script>
  window.addEventListener('message', function (event) {
    event.data.message_type && console.log('got block message', event.data)

    if (event.data.message_type === 'close_popup') {
      window.iframe_popup.removeChild(window.iframe_popup.querySelector('iframe'))
      window.iframe_popup.style.display = 'none'
    }

    if (event.data.message_type === 'open_popup') {

      var iframe = document.createElement('iframe')
      iframe.src = event.data.url
      iframe.width = '100%'
      iframe.id = 'iframe_content'
      iframe.height = '100%'
      iframe.frameBorder = '0'
      iframe.scrolling = 'no'
      iframe.style = 'border: 0; display: block; position: absolute; top:0; left: -200vw'
      iframe.onload = function () {
        iframe.style.left = '0'
      }
      window.iframe_popup.appendChild(iframe)
      window.iframe_popup.style.display = 'block'
    }

    if (event.data.message_type === 'resize') {
      var iframes = document.querySelectorAll(\`iframe[src="\${event.data.url}"]\`)

      if (iframes.length) {
        iframes[0].style.height = event.data.frameHeight + 'px'
      }
    }
  })
</script>`

export const iframeResizeCode = `<script>
function onResize() {
  console.log('post update-block-height message');
  window.parent.postMessage({ event: 'update-block-height', height: document.body.scrollHeight }, '*');
}
window.addEventListener('load', onResize);
window.addEventListener('resize', onResize);
</script>`
